import React from "react";
import { Link } from "react-router-dom";

function HireTeam() {
  return (
    <section class="hire-team">
      <div class="container">
        <div class="hire-team__inner">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="hire-team__content">
                <h2 class="secondary-title">Hire your remote dream team!</h2>
                <p class="hire-team__description secondary-text">
                  Hire your remote team that is handpinked through technical
                  tests. Junior and experienced developers, data scientists,
                  devOps and designers at your disposal according to your
                  business needs.
                </p>
                <Link to="/hire-talents" style={{ textDecoration: "none" }}>
                  <a href="hire-talents" class="btn btn-primary">
                    Hire remote developers
                  </a>
                </Link>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="hire-team__img-wrapper">
                <img
                  class="hire-team__img"
                  src="assets/img/img_hire-remote.jpg"
                  srcset="assets/img/img_hire-remote@2x.jpg 2x"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HireTeam;
