import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import EuroCompanies from "./euro-companies";
import Fqa from "./fqa";
import HeroJoinUs from "./hero-join-us"
import HowToJoin from "./how-to-join";
import OtherPositions from "./other-positions";
import Skills from "./skills";
import TestimonialDark from "./testimonial-dark";
import TestimonialLight from "./testimonial-light";
import Values from "./values";
import WhyFd from "./why-fd";
import Footer from "../components/footer"
function JoinUs() {
  useEffect(()=> {
    window.scroll(0,0)
  },[])
  return (
    <div class="join-us">
      <Navbar />
      <main class="main">
        <HeroJoinUs/>
        <EuroCompanies/>
        <WhyFd/>
        <TestimonialDark/>
        <HowToJoin/>
        <TestimonialLight/>
        <Skills/>
        <Values/>
        <OtherPositions/>
        <Fqa/>
      </main>
      <Footer/>
    </div>
  );
}

export default JoinUs;
