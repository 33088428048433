import React from "react";
import { PopupButton } from "@typeform/embed-react";

function Values() {
  return (
    <section class="section section--values section--dark">
      <div class="container">
        <h4 class="section__subtitle">Our values</h4>
        <h2 class="section__title section__title--dark mb-5">
          We see every day as an opportunity to turn our values into actions.
        </h2>
        <div class="row gy-5">
          <div class="col-12 col-lg-4 col-md-6">
            <div class="value-item">
              <h5 class="value-item__title">Make it happen.</h5>
              <p class="value-item__desc">
                We have one state of mind: “can do, will do”. It is the most
                important driver to get things done. “We JUST get it done” –
                whether it is a decision to take, a next step to reach, or a
                problem to solve, it is our responsibility to see it done or
                resolved. We reach our goals, we lead and encourage our
                workmates, we ask for help, and we escalate issues if necessary
                and use all available resources.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="value-item">
              <h5 class="value-item__title">Think customers first.</h5>
              <p class="value-item__desc">
                We do not accept anything other than an excellent customer
                experience. We thrive for it. Everyone owns his/her workstream
                and plays a part in delivery the best possible outcome. We know
                perfectly our product and really understand what we are doing
                and trying to achieve. We are curious to know our clients – we
                seek, listen, and apply their feedback
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="value-item">
              <h5 class="value-item__title">Focus & embrace change.</h5>
              <p class="value-item__desc">
                We stay focus on our priorities and what it takes to achieve
                them. We do not distract ourselves by every day noise. We always
                act quickly and adapt rapidly to changing circumstances – this
                is our modus operandi. Our ability to quickly react to change is
                the fuel that runs our growth engine. Working with us is a
                rollercoaster. Buckle up, stay positive, have an impact, and
                enjoy the ride. It is a unique experience
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="value-item">
              <h5 class="value-item__title">Make smart contributions.</h5>
              <p class="value-item__desc">
                Always ask yourself: what problem are we trying to solve?
                Identify the core issues from the onset. Have a plan for
                everything that is measurable and based on facts, not fiction.
                If we do not have enough information on hand, we look for it and
                find it. We challenge ourselves and others. We are creative and
                go out of the beaten path to find solutions.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="value-item">
              <h5 class="value-item__title">Play as ONE.</h5>
              <p class="value-item__desc">
                We are competitive with ourselves, not with our workmates – we
                act as one team. We work hard and have fun doing it. We do not
                take ourselves too seriously, laugh every day, and celebrate our
                wins. We are kind, respectful, and inclusive. We are our
                authentic selves with one another. We do not pass on judgment,
                but are curious and open to other opinions.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="value-item">
              <h5 class="value-item__title">
                It's not a work, It's a mission.
              </h5>
              <p class="value-item__desc">
                It is not a job like any other. It is a mission that we aim to
                accomplish together: help people’s lives through technology.
                This mission has an impact on our families, our friends, our
                neighbours. We are useful to the society in which we live and
                aspire to make the world a better place.
              </p>
            </div>
          </div>
        </div>
        <div class="section__cta">
          <p class="section__cta-text">You think we see things the same way?</p>
          <PopupButton
            id="rjDfDCxF"
            size={75}
            // data-tf-iframe-props="title=Join our team"
            // data-tf-medium="snippet"
            // href="job-details.html"
            className="btn btn-primary"
          >
            Apply for jobs
          </PopupButton>
        </div>
      </div>
    </section>
  );
}

export default Values;
