import React from "react";
import { Link } from "react-router-dom";

function Tech() {
  return (
    <section class="tech">
      <div class="container">
        <div class="tech__inner">
          <div class="tech__title-text">
            <p class="primary-text">
              You don’t need talents but you have a project in mind ?
            </p>
            <h1 class="tech__title primary-title">
              We build world class web applications and cross-platform mobile
              apps that will help grow your business
            </h1>
          </div>
          <div class="tech__description">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="tech__img-wrapper">
                  <img
                    class="tech__img"
                    src="assets/img/img_tech.jpg"
                    srcset="assets/img/img_tech@2x.jpg 2x"
                    alt=""
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="tech__content">
                  <p class="primary-text">
                    We have an experienced team of developers, data scientists
                    and designers ready to build your project from the idea to
                    the development and maintenance.
                  </p>
                  <p class="primary-text">
                    We develop with technologies that have proven themselves on
                    the market. Our teams evolve with the technologies and needs
                    of our customers. We invest a significant part of our income
                    in training.
                  </p>
                <Link to="/start-project" style={{ textDecoration: "none" }}>
                  
                  <a href="start-project.html" class="btn btn-primary">
                    Start your project now
                  </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tech;
