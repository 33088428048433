import React from 'react'
import { Link } from "react-router-dom";

function Career() {
  return (
    <section class="career">
      <div class="container">
        <div class="career__inner">
          <div class="career__titles">
            <h1 class="primary-title">Take your career to the next level by joining our dream team</h1>
            <p class="career__desc secondary-text">Grow your career and build expertise by working with international
              teams wherever you are.</p>
          </div>
          <div class="career__description">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="career__img-wrapper">
                  <img class="career__img" src="assets/img/img_career.jpg" srcset="assets/img/img_career@2x.jpg 2x" alt=""/>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="career__content">
                  <div class="career__advantages">
                    <div class="career__icon">
                      <img src="assets/img/icons/icn_rectangle.svg" alt=""/>
                    </div>
                    <p class="career__text secondary-text">Be paid in euros and dollars</p>
                  </div>
                  <div class="career__advantages">
                    <div class="career__icon">
                      <img src="assets/img/icons/icn_rectangle.svg" alt=""/>
                    </div>
                    <p class="career__text secondary-text">Find a long term job</p>
                  </div>
                  <div class="career__advantages">
                    <div class="career__icon">
                      <img src="assets/img/icons/icn_rectangle.svg" alt=""/>
                    </div>
                    <p class="career__text secondary-text">Flexible working style (remote or office)</p>
                  </div>
                  <div class="career__advantages">
                    <div class="career__icon">
                      <img src="assets/img/icons/icn_rectangle.svg" alt=""/>
                    </div>
                    <p class="career__text secondary-text">Annual bonus</p>
                  </div>
                  <div class="career__advantages">
                    <div class="career__icon">
                      <img src="assets/img/icons/icn_rectangle.svg" alt=""/>
                    </div>
                    <p class="career__text secondary-text">Annual training budget</p>
                  </div>
                  <Link to="/join-us" style={{ textDecoration: "none" }}>

                  <a href="join-us.html" class="btn btn-primary">Apply for jobs</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Career