import React from 'react'

function Loader() {
  return (
    <div class="loader">
      <div class="loader__box">
        <img src="assets/img/icons/fd_logo.svg" alt=""/>
      </div>
    </div>
  )
}

export default Loader