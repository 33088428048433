import React from "react";

function FdInfo() {
  return (
    <section class="fd-info">
      <div class="container">
        <div class="fd-info__inner">
          <h1 class="big-title">Let’s start a project together</h1>
          <div class="fd-info__desc">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="fd-info__content">
                  <h4 class="fd-info__second-title">
                    {" "}
                    hello@factorydigitale.tech
                  </h4>
                  <p class="fd-info__text">New businesses, new opportunities</p>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="fd-info__content">
                  <h4 class="fd-info__second-title">+33 6 32 63 19 89</h4>
                  <p class="fd-info__text">
                    You can call from 8:00 AM to 5:00 PM
                  </p>
                </div>
              </div>
              <div class="col-sm-8 col-md-6 col-lg-4">
                <div class="fd-info__content fd-info__content--moveTop">
                  <h4 class="fd-info__second-title">Our offices</h4>
                  <p class="footer__street">
                    60 rue François 1er, 75008, Paris, France
                  </p>
                  <p class="footer__street">Alger, Casablanca, Tunis</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FdInfo;
