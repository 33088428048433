import React from "react";

function Skills() {
  return (
    <section class="section section--skills">
      <div class="container">
        <h2 class="section__title">Skills we work with</h2>
        <p class="section__desc mx-auto">
          We develop with technologies that have proven themselves on the
          market. Our teams evolve with the technologies and needs of our
          customers. We invest a significant part of our income in the training
          and coaching of our teams.
        </p>
        <div class="skills-list mx-auto">
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-js.svg"
              alt="logo javascript"
            />
            <span class="skill-badge__name">Javascript</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-node.svg"
              alt="logo node"
            />
            <span class="skill-badge__name">Node</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-bootstrap.svg"
              alt="logo bootstrap"
            />
            <span class="skill-badge__name">Bootstrap</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-docker.svg"
              alt="logo docker"
            />
            <span class="skill-badge__name">Docker</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-figma.svg"
              alt="logo figma"
            />
            <span class="skill-badge__name">Figma</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-html.svg"
              alt="logo html"
            />
            <span class="skill-badge__name">HTML5</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-firebase.svg"
              alt="logo firebase"
            />
            <span class="skill-badge__name">Firebase</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-react.svg"
              alt="logo javascript"
            />
            <span class="skill-badge__name">React Native</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-angular.svg"
              alt="logo angular"
            />
            <span class="skill-badge__name">Angular</span>
          </div>
          /
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-python.svg"
              alt="logo javascript"
            />
            <span class="skill-badge__name">Python</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-php.svg"
              alt="logo php"
            />
            <span class="skill-badge__name">PHP</span>
          </div>
          <div class="skill-badge rounded-pill">
            <img
              class="skill-badge__logo"
              src="assets/img/logo-ruby.svg"
              alt="logo Ruby"
            />
            <span class="skill-badge__name">Ruby on Rails</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
