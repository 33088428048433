import React from "react";
import Navbar from "../components/navbar";
import Hero from "./hero";
import HireTeam from "./hire-team";
import Tech from "./tech";
import NotTech from "./not-tech";
import Career from "./career";
import Fqa from "./fqa";
import Footer from "../components/footer";

function Home() {
  
 
  return (
    <div className="home">
      <Navbar />
      <main class="main">
        <Hero />
        <HireTeam />
        <Tech />
        <NotTech />
        <Career />
        <Fqa />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
