import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Loader from "./pages/components/loader";
import OurSchool from "./pages/our-school";
import StartProject from "./pages/start-project";
import JoinUs from "./pages/join-us";
import HireTalents from "./pages/hire-talents";
import Page500 from "./pages/hire-talents/500";
import Privacy from "./pages/privacy-policy";

function App() {
  window.addEventListener("load", function () {
    document.querySelector(".App").classList.remove("loading");
  });

  return (
    <BrowserRouter>
      <div className="App global  loading">
        <Loader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-school" element={<OurSchool />} />
          <Route path="/start-project" element={<StartProject />} />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/hire-talents" element={<HireTalents />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
