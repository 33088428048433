import React,{useEffect} from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import FdInfo from "./fd-info";
import Typeform from "./typeform";
function StartProject() {
  useEffect(()=> {
    window.scroll(0,0)
  },[])
  return (
    <div className="start-project">
      <Navbar className="nav-master-white" ourSchoolActive={2} />
      <main class="main">
        <FdInfo />
        <Typeform />
      </main>
      <Footer />
    </div>
  );
}

export default StartProject;
