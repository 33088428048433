import React from "react";
import { Widget } from "@typeform/embed-react";

function Typeform() {
  return (
    <section class="typeform">
      <div class="container">
        <div class="typeform__inner">
          <Widget
            id="kQkdV4AV"
            // data-tf-iframe-props="title=Start a project"
            // data-tf-medium="snippet"
            style={{width:'100%',height:'550px'}}
          />
        </div>
      </div>
    </section>
  );
}

export default Typeform;
