import React from "react";

function NotTech() {
  return (
    <section class="not-tech">
      <div class="container">
        <div class="not-tech__inner">
          <h1 class="primary-title">Because we don’t do just tech</h1>
          <div class="not-tech__description">
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="bloc-item">
                  <div class="bloc-item__icon-wrapper">
                    <img
                      class="bloc-item__icon"
                      src="assets/img/icons/icn_web-marketing.svg"
                      alt=""
                    />
                  </div>
                  <h4 class="primary-text">Web Marketing</h4>
                  <p class="bloc-item__text">
                    We provide you community management, content creation and
                    paid social media campaign to increase your revenue and
                    profits through optimized marketing performance.
                  </p>
                  <a href="/hire-talents" class="bloc-item__hire">
                    Hire talent
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="bloc-item">
                  <div class="bloc-item__icon-wrapper">
                    <img
                      class="bloc-item__icon"
                      src="assets/img/icons/icn_backoffice.svg"
                      alt=""
                    />
                  </div>
                  <h4 class="primary-text">Backoffice</h4>
                  <p class="bloc-item__text">
                    We offer top resources of junior or senior talents according
                    to your needs for your different activities : accounting,
                    KYC, etc.
                  </p>
                  <a href="hire-talents" class="bloc-item__hire">
                    Hire talent
                  </a>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="bloc-item">
                  <div class="bloc-item__icon-wrapper">
                    <img
                      class="bloc-item__icon"
                      src="assets/img/icons/icn_customer.svg"
                      alt=""
                    />
                  </div>
                  <h4 class="primary-text">Customer Experience</h4>
                  <p class="bloc-item__text">
                    We support our clients on all client and/or prospect touch
                    points : direct sales, customer service and after-sales
                    service.
                  </p>
                  <a href="hire-talents" class="bloc-item__hire">
                    Hire talent
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotTech;
