import React from 'react'

function SchoolVideo() {
  return (
    <section class="school-video">
            <div class="container">
              <div class="video-player plyr">
              <video id="player" class="player" playsinline controls >
              <source src="assets/img/code213-school-teaser.mp4"/>
            </video>
              </div>
            </div>
          </section>
  )
}

export default SchoolVideo