import React, { useState,useEffect } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import HeroTalents from "./hero-talents";
import Talents from "./talents";
import Pagination from "./pagination";
import NoResults from "./no-results";

function HireTalents() {
  const [data, setData] = useState();
  const [talents, setTalents] = useState();
  const [pageNum, setPageNum] = useState(1);
  const searchData = (data) => {
    setData(data);
  };
  const talentsData = (data) => {
    setTalents(data);
  };
  const getPageNum = (pageNum) => {
    setPageNum(pageNum);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className=" hire-talents">
      <Navbar />
      <main class="main">
        <HeroTalents searchData={searchData} talentsData={talentsData}/>
        {
          talents&&talents?.total===0 ? 
          <NoResults/>
          :
          <>
          <Talents data={data} talentsData={talentsData} pageNum={pageNum} />
          <Pagination talents={talents} getPageNum={getPageNum} />
          </>
        }
        
      </main>
      <Footer />
    </div>
  );
}

export default HireTalents;
