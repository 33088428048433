import React from "react";

function WhyFd() {
  return (
    <section class="section section--why-fd">
      <div class="container">
        <h2 class="section__title">Why choose Factory Digitale?</h2>
        <p class="section__desc">
          Join an exclusive developers community and build your career.
        </p>
        <div class="row gy-5">
          <div class="col-12 col-lg-4 col-md-6">
            <div class="perk-item">
              <div class="perk-item__icon-box">
                <img src="assets/img/perk_icon_arrow.svg" alt="" />
              </div>
              <h4 class="perk-item__title">Annual budget training</h4>
              <p class="perk-item__desc">
                We support growth, you get a yearly budget to spend on things
                that make you learn and grow your skills and your career, year
                over year.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="perk-item">
              <div class="perk-item__icon-box">
                <img src="assets/img/perk_icon_money.svg" alt="" />
              </div>
              <h4 class="perk-item__title">Annual bonus</h4>
              <p class="perk-item__desc">
                Other then offering a competitif salary at the beginning of your
                journey with us. You can make it jumps depending on your added
                value to the company. An annual bonus at the beginning of every
                year is a standard practice.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="perk-item">
              <div class="perk-item__icon-box">
                <img src="assets/img/perk_icon_puzzle.svg" alt="" />
              </div>
              <h4 class="perk-item__title">Healthcare insurance</h4>
              <p class="perk-item__desc">
                We believe tha happy and healthy go together. We cover medical,
                dental and vision for you and yours.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="perk-item">
              <div class="perk-item__icon-box">
                <img src="assets/img/perk_icon_island.svg" alt="" />
              </div>
              <h4 class="perk-item__title">30 annual holiday days</h4>
              <p class="perk-item__desc">
                Work is important, but so is everything else. We offer 30 days -
                fully paid- a year so you can recharge regularly to stay fresh
                and productive at work.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="perk-item">
              <div class="perk-item__icon-box">
                <img src="assets/img/perk_icon_vase.svg" alt="" />
              </div>
              <h4 class="perk-item__title">Remote working style</h4>
              <p class="perk-item__desc">
                Many people on our team work from home one or two days a week.
                We can be flexible so you can choose whatever setup suits you
                the best.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 col-md-6">
            <div class="perk-item">
              <div class="perk-item__icon-box">
                <img src="assets/img/perk_icon_gift.svg" alt="" />
              </div>
              <h4 class="perk-item__title">Exclusive opportunities</h4>
              <p class="perk-item__desc">
                We offer exclusive opportunities in the long term, big discounts
                on our inHome services and so on.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyFd;
