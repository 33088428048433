import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
function Privacy() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navbar className="nav-master-white" />
      <main class="main">
        <section class="cgu">
          <div class="container">
            <div class="cgu__inner">
              <h1 class="cgu__title">Privacy policy</h1>

              <p class="tertiary-text">
                <strong>Factory Digitale</strong> is committed to upholding the
                privacy rights of individuals. Factory Digitale provides this
                Privacy Policy to let you know our policies and procedures
                regarding the collection, use and disclosure of information
                through www.factorydigitale.tech (the “Site”), and any other
                websites, features, applications, widgets or online services
                that are owned or controlled by Factory Digitale and that post a
                link to or incorporate the terms of this Privacy Policy
                (together with the Site, the “Site Services”), as well as any
                information Factory Digitale collects offline in connection with
                the Site Services and through other means such as our events,
                sales and marketing activities. This Privacy Policy also
                explains the collection, use, purpose, and sharing of personally
                identifiable information related to Factory Digitale’s employees
                and Factory Digitale’s providing of services to our customers.
              </p>

              <p class="tertiary-text">
                Factory Digitale does not require you to register or provide PII
                to visit our Site. By accessing our Site or using our Site
                Services, you agree to this Privacy Policy in addition to any
                other agreements we might have with you.
              </p>

              <h3 class="primary-text">DEFINITIONS</h3>
              <p class="tertiary-text">
                <strong>“Factory Digitale”</strong> means Edtech Services, a
                French corporation. We may also refer to Factory Digitale as
                “we”, “us”, or “our”.
              </p>
              <p class="tertiary-text">
                <strong>“Factory Digitale Client”</strong> means any customer
                that has entered into an agreement with Factory Digitale to use
                Factory Digitale’s services.
              </p>
              <p class="tertiary-text">
                <strong>“Factory Digitale Engineer”</strong> means any
                authorized member of the Factory Digitale Talent Network who has
                agreed to the Talent Network Terms & Conditions.
              </p>
              <p class="tertiary-text">
                <strong>“PII”</strong> or{" "}
                <strong>“Personally Identifiable Information”</strong> means any
                information which may identify an individual, directly or
                indirectly. Examples of PII include, but are not limited to,
                first and last name, home address, billing address or other
                physical address, email address, telephone number, etc. PII does
                not include information which is anonymized or aggregated, and
                other information which is excluded from the scope of applicable
                privacy laws.
              </p>
              <p class="tertiary-text">
                <strong>“Visitor”</strong> refers to anyone accessing the
                Factory Digitale Site, www.factorydigitale.tech.
              </p>

              <h3 class="primary-text">QUICK LINKS</h3>
              <p class="tertiary-text">
                We recommend that you read this Privacy Policy to ensure you are
                fully informed. However, if you only want to access a particular
                section of this Privacy Policy, then you can click on the
                relevant link below to jump to that section.
              </p>

              <ol class="unordered-list">
                <li class="unordered-list__item">
                  <a href="#information-collection" class="tertiary-text">
                    INFORMATION COLLECTION
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#general-information" class="tertiary-text">
                    GENERAL INFORMATION ON OUR USE OF PII
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#use-thired" class="tertiary-text">
                    USE OF THIRD-PARTY RESOURCES, APPLICATIONS AND WEBSITES
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#onward-transfert" class="tertiary-text">
                    OTHER ONWARD TRANSFERS
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#choices-rights" class="tertiary-text">
                    YOUR CHOICES & RIGHTS
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#security" class="tertiary-text">
                    SECURITY
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#external-sites" class="tertiary-text">
                    LINKS TO EXTERNAL SITES
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#contact-us" class="tertiary-text">
                    CONTACT US
                  </a>
                </li>
                <li class="unordered-list__item">
                  <a href="#changes-privacy" class="tertiary-text">
                    CHANGES TO OUR PRIVACY POLICY
                  </a>
                </li>
              </ol>

              <h2 class="cgu__secondary-title" id="information-collection">
                1. INFORMATION COLLECTION{" "}
              </h2>
              <h5>
                <strong>A. Information Collected Automatically</strong>
              </h5>
              <p class="tertiary-text">
                Like other companies with online services, we receive technical
                information when you use our Site or access certain Site
                Services. We use these technologies to analyze how people use
                the Site Services and to improve how our Site and Site Services
                function.
              </p>
              <p class="tertiary-text">
                We and our third-party service providers, including analytics
                and third-party content providers, may automatically collect the
                following information from you whenever you access or interact
                with the Site or Site Services: pages visited, links clicked,
                text entered into contact forms or chat widgets, and mouse
                movements. We may also collect the referring URL, browser,
                operating system, and Internet Protocol (“IP”) address you used
                to access the Site Services. We (and/or our service providers)
                may use publicly available sources to approximate your
                geographic region and Internet Service Provider based on your IP
                address. We collect this information to improve the services we
                offer you, and to improve marketing, analytics, and the
                functionality of our Site Services.
              </p>
              <h5>
                <strong>
                  B. Information You Voluntarily Provide to Factory Digitale
                </strong>
              </h5>
              <p class="tertiary-text">
                Site Visitors may choose to interact with the Site in a way that
                results in your providing PII to Factory Digitale, such as
                giving us your name, email address, phone number, and country
                location when signing up to learn more about Factory Digitale’s
                Client services or when signing up to apply to be an Factory
                Digitale Engineer in the Factory Digitale Talent Network. If you
                make use of our Site to register for certain services, download
                e-books, attend virtual events or webinars, complete online
                registration forms, apply for roles via our Site, or to create
                or update any of your marketing preferences, you will be asked
                to provide personal information. If you choose to provide us
                with any PII, we will use the information provided to respond to
                your request.
              </p>
              <p class="tertiary-text">
                If you provide us with your consent to be contacted for
                marketing purposes, we may send you information about our
                products and services, including marketing communications, as
                follows:
              </p>
              <ul class="ordered-list">
                <li class="ordered-list__item tertiary-text">
                  To market events, promotions, competitions, webinars, reports,
                  our services, news or relevant industry updates.
                </li>
                <li class="ordered-list__item tertiary-text">
                  Depending on which jurisdiction you are in, we may be required
                  to give you an option to “opt-in” and we will always provide
                  you with an option to “opt-out” with each marketing
                  communication.
                </li>
              </ul>
              <p class="tertiary-text">
                While Visitors do not have a statutory obligation to provide us
                with any information, Factory Digitale Engineers may have a
                contractual obligation (pursuant to the Talent Network Terms &
                Conditions) to do so, and if we do not receive certain
                information from you, then we will not be able to provide
                certain Site Services to you. If you have any questions
                regarding whether the provision of information is mandatory and
                the consequences for withholding such information, please
                contact our Privacy Officer using the contact information below.
              </p>
              <h2 class="cgu__secondary-title" id="general-information">
                2. GENERAL INFORMATION ON OUR USE OF PII
              </h2>
              <p class="tertiary-text">
                Use of or access to our Site does not require you to provide any
                PII to us. Factory Digitale does not buy or sell PII for its
                business practices or for commercial purposes.
              </p>
              <p class="tertiary-text">
                Factory Digitale stores, maintains, processes, and/or handles
                PII data of Factory Digitale employees as necessary to perform
                its business functions for our employees.
              </p>
              <p class="tertiary-text">
                We also may receive PII from Factory Digitale Clients to perform
                services for them. Any PII used for such purposes will be
                limited to access by only those who require it given their job
                function at Factory Digitale, and except as otherwise provided
                herein, we only share the PII back with our respective Clients.
                Factory Digitale does not retain, use or disclose any personal
                information collected on behalf of our Clients for any purpose
                (including any commercial purpose) other than the specific
                purpose of performing the services as specified in the
                applicable Master Services Agreement with the Client.
              </p>
              <p class="tertiary-text">
                Factory Digitale may provide PII of our users to only those of
                its personnel and third-party vendors that (1) need to know such
                information in order to process it on Factory Digitale’s behalf
                or to provide services as described above, and (2) have agreed
                in writing not to disclose it to any other parties without
                Factory Digitale’s consent. Some of those employees, contractors
                and affiliated organizations may be located outside of your home
                country; by using the Site and/or Site Services, you consent to
                the transfer of information to such individuals and
                organizations in order to accomplish these purposes.
              </p>
              <p class="tertiary-text">
                In some instances, we may be required by law to disclose
                information you submit or email to us electronically by law,
                court order, law enforcement request or other purposes to
                protect our sites or other sites due to security threats. We
                may, as necessary, disclose this information or any other
                information that may identify you to state and local authorities
                or law enforcement as required under law or by court order.
              </p>

              <h2 class="cgu__secondary-title" id="use-thired">
                3. USE OF THIRD-PARTY RESOURCES, APPLICATIONS AND WEBSITES
              </h2>
              <p class="tertiary-text">
                Factory Digitale actively engages third-party resources,
                applications, and websites. These services provided by
                third-parties are established to enhance user experience,
                provide ease of navigation through Factory Digitale’s sites, and
                to promote access to information. Factory Digitale does not buy
                or sell PII for its business practices or for commercial
                purposes.
              </p>
              <p class="tertiary-text">
                Please note that Factory Digitale does not control, moderate, or
                endorse any comments or opinions provided by visitors on
                third-party websites. The use and creation of official Factory
                Digitale accounts may cause PII to become available or
                accessible to Factory Digitale. Such information may become
                available to Factory Digitale when a user provides, submits,
                communicates, links, posts, or associates information with
                designated Factory Digitale accounts on social media (e.g., by
                using “hashtags”, “liking”, “friending”, “tweeting”, commenting
                on any content or media on our designated accounts). At times,
                Factory Digitale, through its personnel, employees, officers,
                directors, agents and assignees, may respond publicly to content
                or media made available though “share,” “retweet,” “friend,”
                “follow,” or similar activities, or respond publicly on content
                made on designated and official Factory Digitale accounts.
                Factory Digitale may disseminate any of such information or PII
                to law enforcement officials, as required by law or court order.
              </p>
              <p class="tertiary-text">
                From time to time, Factory Digitale may engage in joint
                marketing events with select business partners. If you register
                for a joint webinar or specifically express an interest in a
                jointly-offered product, promotion or service, we may share
                relevant PII with those partner(s). Where you have given your
                consent to do so, these business partners may send you marketing
                communications about their own products and services.
              </p>
              <p class="tertiary-text">
                In addition to the automatic and voluntary provided information
                collected by Factory Digitale, third-parties may host embedded
                content, applications on the webpage, and/or cookies that
                collect information from your machine while you are on our
                website. These services provide Factory Digitale with website
                analytics, enhance the user experience, provide ease of
                navigation through Factory Digitale’s sites, send tailored
                marketing communications or ads, and promote your access to
                information.
              </p>
              <p class="tertiary-text">
                Please be aware that Factory Digitale does not control our
                business partners’ use of PII and this Privacy Policy does not
                apply to third party websites or services. Such entities are
                responsible for managing their own use of the PII collected in
                these circumstances. You may wish to review their privacy
                policies for details about how each third party collects, uses,
                and shares information. You can control the use of cookies at
                the individual browser level.
              </p>
              <p class="tertiary-text">
                In relation to third-party advertising on our website or through
                any advertising entities, you may opt-out of being served
                personalized advertisements by clicking on these two links (US
                and EU).
              </p>

              <h2 class="cgu__secondary-title" id="onward-transfert">
                4. OTHER ONWARD TRANSFERS
              </h2>

              <p class="tertiary-text">
                Factory Digitale may transfer data to third-party vendors acting
                as our agents, due to an acquisition of business assets by
                another entity, in the ordinary course of business due to
                expansions or fund-raising, or as otherwise prescribed by this
                policy. In the course of any change of ownership, Factory
                Digitale shall notify any impacted parties in writing of any
                transfer of PII.
              </p>
              <p class="tertiary-text">
                All transfers of PII will be: (1) limited for specific purposes;
                (2) based on a proper determination that the recipient shall:
                (i) adhere to Privacy Shield principles; (ii) notify Factory
                Digitale if they determine at any subsequent time they will not
                be able to adhere to Privacy Shield principles; and (iii) stop
                and remediate any unauthorized processing; and (3) based on
                contractually established privacy terms with the recipient of
                such information that can be either: (i) shared with you; or
                (ii) reasonably summarized for you. Any transfer of Factory
                Digitale employee PII will be consistent with our internal
                privacy practices and as disclosed in our employee handbook.
              </p>
              <p class="tertiary-text">
                Factory Digitale is responsible and liable for the processing of
                PII it receives and transfers to a third party acting on Factory
                Digitale’s behalf. Factory Digitale complies with the Privacy
                Shield Principles for all onward transfers of PII from the EU
                including liability provisions of such onward transfer.
              </p>

              <h2 class="cgu__secondary-title" id="choices-rights">
                5. YOUR CHOICES & RIGHTS
              </h2>
              <p class="tertiary-text">
                You can use our Site without providing any personal identifiable
                information. However, if you choose not to provide any PII, you
                may not be able to use certain Site Services.
              </p>
              <p class="tertiary-text">
                Individuals also have the opportunity to choose whether their
                PII is (1) to be disclosed to a third party or (2) to be used
                for a purpose other than the purpose for which it was originally
                collected or subsequently authorized by the individual. In
                accordance with applicable law, you may have the right to:
              </p>
              <ul class="ordered-list">
                <li class="ordered-list__item tertiary-text">
                  Access Personal Information. You may request confirmation of
                  whether we are processing your personal information and obtain
                  a copy of the categories of information we may have collected
                  on your behalf.
                </li>
                <li class="ordered-list__item tertiary-text">
                  Request Correction. You may request correction of your
                  personal information where it is inaccurate, incomplete or
                  outdated. For Factory Digitale Engineers, you may update your
                  personal information within your Factory Digitale accounts by
                  logging in and updating your profile.
                </li>
                <li class="ordered-list__item tertiary-text">
                  Request Deletion (“Opt Out”) or Withdraw Consent. You may
                  request to remove your PII from our records. Note that if your
                  personal information is deleted, you may no longer access or
                  be able to use certain Site Services, and any account you have
                  with us may become deactivated.
                </li>
                <li class="ordered-list__item tertiary-text">
                  Direct Marketing Opt Out. You have the right to object if we
                  are processing your PII for direct marketing purposes. You can
                  unsubscribe from Factory Digitale’s own marketing emails at
                  any time by clicking the “unsubscribe” link they contain or by
                  accessing the Email Preferences Center.
                </li>
              </ul>
              <p class="tertiary-text">
                If you wish to access, correct, amend, or delete PII you have
                provided directly to us, please contact our Privacy Officer at{" "}
                <a href="" class="text-decoration-none">
                  hello@factorydigitale.tech
                </a>
                . For deletion/opt out requests, please include the subject line
                “OPT OUT”. We will respond to requests within forty-five (45)
                days. If we require more time, we will inform you of the reason
                and extension period for a response in writing.
              </p>
              <p class="tertiary-text">
                If you wish to access, correct, amend, or delete PII you have
                provided to our Clients, please contact those Factory Digitale
                Clients directly. Alternatively, you may contact our Privacy
                Officer at{" "}
                <a href="" class="text-decoration-none">
                  hello@factorydigitale.tech
                </a>{" "}
                and we will pass your request along to the applicable Factory
                Digitale Client.
              </p>
              <p class="tertiary-text">
                When any Client requests Factory Digitale to remove any Client
                data that Factory Digitale may have on its systems, Factory
                Digitale will respond as soon as practicably as possible, and no
                more than forty-five (45) days after such receipt from our
                Client. If you wish to no longer have your PII used or disclosed
                by our Clients, or by us in connection with our work for our
                Clients, please contact the Client that you interact with
                directly. Alternatively, you may contact our Privacy Officer at{" "}
                <a href="" class="text-decoration-none">
                  hello@factorydigitale.tech
                </a>{" "}
                and we will pass your request along to our Clients.
              </p>
              <h2 class="cgu__secondary-title" id="security">
                6. SECURITY
              </h2>
              <p class="tertiary-text">
                Factory Digitale uses industry-standard technologies,
                administrative, technical, and physical measures to protect the
                security, confidentiality and integrity of any information or
                data collected by us.
              </p>
              <p class="tertiary-text">
                However, no website, service, mobile application, database or
                system is completely secure or “hacker proof.” You are
                responsible for taking reasonable steps to protect your personal
                information against unauthorized disclosure or misuse.
              </p>

              <h2 class="cgu__secondary-title" id="external-sites">
                7. LINKS TO EXTERNAL SITES
              </h2>
              <p class="tertiary-text">
                Our Site and Site Services may contain links to websites created
                and maintained by other public or private organizations. We
                provide these links as a service to our users. Please note that
                when you follow a link to an external site, you will be leaving
                Factory Digitale’s Site Services and are subject to the privacy
                and security policies of the site to which you are visiting.
                Factory Digitale is not responsible for the security practices
                or the content of such external sites.
              </p>
              <h2 class="cgu__secondary-title" id="contact-us">
                8. CONTACT US
              </h2>
              <p class="tertiary-text">
                We welcome feedback from all our users and if you have any
                question regarding our privacy policy or the use of your
                information please contact us. You can email our privacy officer
                at{" "}
                <a href="" class="text-decoration-none">
                  hello@factorydigitale.tech
                </a>
              </p>
              <h2 class="cgu__secondary-title" id="changes-privacy">
                09. CHANGES TO OUR PRIVACY POLICY
              </h2>
              <p class="tertiary-text">
                Factory Digitale may amend this Privacy Policy at any time by
                posting a new version to our Site. It is your responsibility to
                review this Privacy Policy periodically as your continued use of
                the Site and the Site Services represents your agreement with
                the then-current Privacy Policy.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Privacy;
