import React from "react";

function Fqa() {
  return (
    <section class="fqa">
      <div class="container">
        <h1 class="primary-title">Frequently asked questions</h1>
        <div class="accordion custom-accordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed primary-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                What technologies do you work with ?
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body tertiary-text">
                We work mainly with all the tech that our clients use.
              </div>
            </div>
          </div>
          <hr class="separator" />
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed primary-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How can we start a project together ?
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body tertiary-text">
                Click on "Start a project" and fill in the form.
              </div>
            </div>
          </div>
          <hr class="separator" />
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed primary-text"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How does the hiring process work ?
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body tertiary-text">
                Our hiring process is compromised of three steps
                <ul>
                  <li>Complete an application form.</li>
                  <li>Take our tests.</li>
                  <li>Take interviews, and recieve job offers.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Fqa;
