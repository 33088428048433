const NoResults = () => {
  return (
    <section class="no-results" id="section-class">
        <div  class="container" id="result-container">
        <div class="no-results__inner">
            <div class="no-results__img-wrapper">
            <img src="assets/img/no_results.svg" alt=""/>
            </div>
            <div class="no-results__content">
                <p class="no-results__text">no search results are found, try again.</p>
            </div>
        </div>
    </div>
    </section>
  )
}

export default NoResults