import React from "react";
import { PopupButton } from "@typeform/embed-react";

function HeroJoinUs() {
  return (
    <section class="hero hero--join-us">
      <div class="container">
        <div class="hero__content">
          <div class="hero__text">
            <h1 class="hero__title">
              Apply for remote software jobs in Europe from the comfort of your
              own home
            </h1>
            <p class="hero__desc">
              Join a network of the world's best developers & get full-time,
              long-term remote software jobs with better compensation and career
              growth.
            </p>
            <div class="hero__btn-cta">
              <PopupButton
                id="rjDfDCxF"
                size={75}
                // data-tf-iframe-props="title=Join our team"
                // data-tf-medium="snippet"
                // href="job-details.html"
                className="btn btn-primary"
              >
                Apply for jobs
              </PopupButton>
            </div>
            <p class="hero__hire-cta">
              You want to hire instead ?
              <a class="btn btn-link btn--hire" href="hire-talents">
                Hire top 1% developers
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="hero__img">
        <img
          src="assets/img/join-us-hero.png"
          srcset="assets/img/join-us-hero@2x.png 2x"
        />
      </div>
    </section>
  );
}

export default HeroJoinUs;
