import React,{useEffect} from "react";
import SchoolInfo from "./school-info";
import SchoolVideo from "./school-video";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

function OurSchool() {
  useEffect(()=> {
    window.scroll(0,0)
  },[])
  return (
    <div className="our-coding-school">
      <Navbar className="nav-master-white" ourSchoolActive={1} />
      <main class="main">
        <SchoolInfo/>
        <SchoolVideo/>
      </main>
      <Footer/>
    </div>
  );
}

export default OurSchool;
