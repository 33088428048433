import React from "react";
import { PopupButton } from "@typeform/embed-react";

function HowToJoin() {
  return (
    <section class="section section--how-to-join">
      <div class="container">
        <h2 class="section__title">How to join our talent tech team?</h2>
        <div class="row">
          <div class="col-12 col-lg-3 col-md-6 mb-4">
            <div class="step-item">
              <span class="step-item__number">[01]</span>
              <h4 class="step-item__title">Complete an application form.</h4>
              <p class="step-item__desc">
                Fill in your basic details - Name, location, skills, salary, &
                experience.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6 mb-4">
            <div class="step-item">
              <span class="step-item__number">[02]</span>
              <h4 class="step-item__title">Take our tests and interviews.</h4>
              <p class="step-item__desc">
                Solve test questions and show up for technical and soft skills
                interview.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6 mb-4">
            <div class="step-item">
              <span class="step-item__number">[03]</span>
              <h4 class="step-item__title">Receive job offers.</h4>
              <p class="step-item__desc">
                Get matched with the best EU companies.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-md-6 mb-4">
            <div class="step-item">
              <span class="step-item__number">[04]</span>
              <h4 class="step-item__title">Land your dream job.</h4>
              <p class="step-item__desc">
                Once you join Factory Digitale, you’ll never have to apply for
                another job.
              </p>
            </div>
          </div>
        </div>
        <div class="section__cta">
          <p class="section__cta-text">Don’t think twice, apply now</p>
          <PopupButton
            id="rjDfDCxF"
            size={75}
            // data-tf-iframe-props="title=Join our team"
            // data-tf-medium="snippet"
            // href="job-details.html"
            className="btn btn-primary"
          >
            Apply for jobs
          </PopupButton>
        </div>
      </div>
    </section>
  );
}

export default HowToJoin;
