import React from "react";

function TestimonialLight() {
  return (
    <section class="section section--testimonial section--light">
      <div class="testimonial-card testimonial-card--light">
        <div class="testimonial-card__img">
          <img
            class="img-fluid w-100"
            src="assets/img/testimon-pic1.png"
            srcset="assets/img/testimon-pic1@2x.png 2x"
            alt=""
          />
          <div class="testimonial-card__squares">
            <div class="square square--white top-0 end-0">
              <div class="position-absolute square square--primary top-100 end-100"></div>
            </div>
            <div class="square square--black bottom-0 end-0"></div>
            <div class="square square--doublew square--same-col-bottom square--light-primary bottom-0 start-0"></div>
          </div>
        </div>
        <div class="testimonial-card__content">
          <h4 class="section__subtitle">Don’t just take our word for it</h4>
          <p class="testimonial-card__desc">
            I can work in the heart of Paris from the confort of my home. With
            great pay and solid growth opportunities, I feel I have the best of
            both worlds.
          </p>
          <div class="testimonial-card__name">Akram, Algeria</div>
          <div class="testimonial-card__job">
            <span>Back-end Developer at</span>
            <img src="assets/img/logo_bfan.svg" alt="Logo Bfan" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialLight;
