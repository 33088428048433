import React, { useState, useEffect } from "react";
import config from "./helpers/config";
import { useNavigate } from "react-router-dom";

function HeroTalents({ searchData,talentsData }) {
  const [filters, setFilters] = useState();
  const [title, setTitle] = useState("");
  const [titles, setTitles] = useState();
  const [countries, setCountries] = useState();
  const [skills, setSkills] = useState();
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [skillsSelected, setSkillsSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    
    const url = `${config.host}/api/candidates/filters`;

    const getFilters = (url) => {
      fetch(url)
        .then((response) => response.json())
        .then((resp) => {
          setFilters(resp);
          setTitles(resp.jobs);
          setCountries(resp.countries);
          setSkills(resp.skills);
        })
        .catch((err) => {
          console.log(err)
           navigate("/500");

        });
    };
    getFilters(url);
  }, []);
  const handleCountries = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setCountriesSelected([...countriesSelected, e.target.value]);
    } else {
      setCountriesSelected(
        countriesSelected.filter((country) => country != e.target.value)
      );
    }
  };
  const handleSkills = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setSkillsSelected([...skillsSelected, e.target.value]);
    } else {
      setSkillsSelected(
        skillsSelected.filter((skill) => skill != e.target.value)
      );
    }
  };
  const resetBtn = () => {
    setSkillsSelected([]);
    setCountriesSelected([]);
    setTitle("");
    searchData([]);
    window.location.reload();
  };
  const handleSearch = (e, title, countriesSelected, skillsSelected, query) => {
    e.preventDefault();
    countriesSelected = JSON.stringify(countriesSelected)
    skillsSelected = JSON.stringify(skillsSelected)
    const url = `${
      config.host
    }/api/candidates/paginate?country=${countriesSelected}&job_title=${title}&skills=${skillsSelected}&query=${query}`;
    fetch(url)
      .then((response) => response.json())
      .then((resp) => {
        searchData(resp.data);
        talentsData(resp)

      })
      .catch((err) => {});
  };
  const searchSkills = (e) => {
    e.preventDefault();
    const filter = e.target.value;
    const skillReg = new RegExp(filter, "i");
    let filteredSkills = [...skills];
    filteredSkills = skills.filter((skill) => skillReg.test(skill));
    setFilteredSkills(filteredSkills);
  };
  const searchCountries = (e) => {
    e.preventDefault();
    const filter = e.target.value;
    const skillReg = new RegExp(filter, "i");
    let filtredCountries = [...countries];
    filtredCountries = countries.filter((country) => skillReg.test(country));
    setFilteredCountries(filtredCountries);
  };
  return (
    <section class="hero-talents">
      <div class="container">
        <div class="hero-talents__inner">
          <div class="hero-talents__titles">
            <h1 class="">Hire top 1% talents</h1>
            <p class="secondary-text">
              Find the best talents that fit your business needs
            </p>
          </div>
          <form class="hero-talents__serach" id="TalentForm">
            <div class="search-form">
              <div class="search-form__inner">
                <input
                  type="search"
                  class="search-form__input"
                  placeholder="Search..."
                  id="global_search"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <div class="search-form__wrapper">
                  <img src="assets/img/icons/icn_search.svg" />
                </div>
              </div>
            </div>
            <div class="dropdown dropdown--custom">
              <button
                class="btn btn-light custom-buttun-filter dropdown-toggle job-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {title ? (
                  <span class="btn-job-title">{title}</span>
                ) : (
                  <span class="btn-job-title">Job title</span>
                )}
                <svg
                  width="17"
                  height="9"
                  viewBox="0 0 19 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M0.730469 1.5L9.36555 9.5L18.0006 1.5"
                    stroke="#041028"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <div
                class="dropdown-menu overflow-hidden"
                aria-labelledby="dropdownMenuButton1"
              >
                <div
                  id="title_container"
                  class="btn-group btn-group--custom"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  {filters ? (
                    titles?.map((title) => (
                      <div>
                        <input
                          type="radio"
                          class="btn-check jobs-btn"
                          name="job-title"
                          value={`${title}`}
                          id={`${title}`}
                          autocomplete="off"
                          onClick={() => {
                            setTitle(title);
                          }}
                        />
                        <label
                          class="btn btn-outline-primary btn-label--custom job-check"
                          for={`${title}`}
                        >
                          {`${title}`}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div class="dropdown dropdown--custom">
              <button
                class="btn btn-light custom-buttun-filter dropdown-toggle skills-btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <div class="skills-content">
                  {skillsSelected.length !== 0 ? (
                    skillsSelected?.map((skill) => (
                      <span class="skill" id={`${skill}`}>
                        {` ${skill} `}
                      </span>
                    ))
                  ) : (
                    <span class="skills-text">Skills</span>
                  )}
                </div>
                <svg
                  class="dropdown__angle-down"
                  width="17"
                  height="9"
                  viewBox="0 0 19 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M0.730469 1.5L9.36555 9.5L18.0006 1.5"
                    stroke="#041028"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <div
                class="dropdown-menu dropdown-menu--custom"
                aria-labelledby="dropdownMenuButton1"
              >
                <div class="search-form">
                  <div class="search-form__inner">
                    <input
                      type="search"
                      class="search-form__input"
                      placeholder="Search..."
                      id="skill_search"
                      onChange={(e) => searchSkills(e)}
                    />
                    <div class="search-form__wrapper">
                      <img src="assets/img/icons/icn_search.svg" />
                    </div>
                  </div>
                </div>
                <div id="skill_container">
                  {filters ? (
                    filteredSkills.length !== 0 ? (
                      filteredSkills?.map((skill) => (
                        <div class="form-check form-check--custom">
                          <input
                            class="form-check-input form-check-input--custom"
                            name="skillcheckbox"
                            type="checkbox"
                            value={`${skill}`}
                            id={`${skill}`}
                            onChange={(e) => handleSkills(e)}
                          />
                          <label
                            class="form-check-label form-check-label--custom"
                            for={`${skill}`}
                          >{`${skill}`}</label>
                        </div>
                      ))
                    ) : (
                      skills?.map((skill) => (
                        <div class="form-check form-check--custom">
                          <input
                            class="form-check-input form-check-input--custom"
                            name="skillcheckbox"
                            type="checkbox"
                            value={`${skill}`}
                            id={`${skill}`}
                            onChange={(e) => handleSkills(e)}
                          />
                          <label
                            class="form-check-label form-check-label--custom"
                            for={`${skill}`}
                          >{`${skill}`}</label>
                        </div>
                      ))
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div class="dropdown dropdown--custom">
              <button
                class="btn btn-light custom-buttun-filter dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <div class="country-content">
                  {countriesSelected.length !== 0 ? (
                    countriesSelected?.map((country) => (
                      <span class="country" id={`${country}`}>
                        {` ${country} `}
                      </span>
                    ))
                  ) : (
                    <span class="country-text">Country</span>
                  )}
                </div>
                <svg
                  width="17"
                  height="9"
                  viewBox="0 0 19 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M0.730469 1.5L9.36555 9.5L18.0006 1.5"
                    stroke="#041028"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <div
                class="dropdown-menu dropdown-menu--custom"
                aria-labelledby="dropdownMenuButton1"
              >
                <div class="search-form">
                  <div class="search-form__inner">
                    <input
                      type="search"
                      class="search-form__input"
                      placeholder="Search..."
                      id="country_search"
                      onChange={(e) => searchCountries(e)}
                    />
                    <div class="search-form__wrapper">
                      <img src="assets/img/icons/icn_search.svg" />
                    </div>
                  </div>
                </div>
                <div id="country_container">
                  {filters ? (
                    filteredCountries.length !== 0 ? (
                      filteredCountries?.map((country) => (
                        <div class="form-check form-check--custom">
                          <input
                            class="form-check-input form-check-input--custom"
                            name="countrycheckbox"
                            type="checkbox"
                            value={`${country}`}
                            id={`${country}`}
                            onChange={(e) => handleCountries(e)}
                          />
                          <label
                            class="form-check-label form-check-label--custom"
                            for={`${country}`}
                          >{`${country}`}</label>
                        </div>
                      ))
                    ) : (
                      countries?.map((country) => (
                        <div class="form-check form-check--custom">
                          <input
                            class="form-check-input form-check-input--custom"
                            name="countrycheckbox"
                            type="checkbox"
                            value={`${country}`}
                            id={`${country}`}
                            onChange={(e) => handleCountries(e)}
                          />
                          <label
                            class="form-check-label form-check-label--custom"
                            for={`${country}`}
                          >{`${country}`}</label>
                        </div>
                      ))
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div class="hero-talents__btn">
              <button
                class="btn btn-outline-light btn-outline-light--custom"
                onClick={(e) =>
                  handleSearch(
                    e,
                    title,
                    countriesSelected,
                    skillsSelected,
                    query
                  )
                }
              >
                Search
              </button>
            </div>
            <div class="hero-talents__btn">
              <button
                type="reset"
                class="btn btn-outline-light btn-outline-light--reset-custom"
                onClick={() => resetBtn()}
              >
                Réinitialiser
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default HeroTalents;
