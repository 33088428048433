import React from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";
function Page500() {
  return (
    <>
      <Navbar className="nav-master-white" />
      <main class="main">
        <section class="no-results">
          <div class="container">
            <div class="no-results__inner">
              <div class="no-results__img-wrapper">
                <img
                  src="assets/img/img_500.svg"
                  class="no-results__img"
                  alt=""
                />
              </div>
              <div class="no-results__content">
                <p class="no-results__text">
                  A server error has occurred, please try again.
                </p>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <a href="index.html" type="submit" class="btn btn-primary">
                    Try again
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Page500;
