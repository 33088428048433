import React, { useEffect, useState } from "react";

function Pagination({ talents, getPageNum }) {
  const [numPages, setNumPages] = useState(1);
  const [current, setCurrent] = useState(1);
  useEffect(() => {
    let num;
    if (talents) {
      num = Math.trunc(talents.total / parseInt(talents.pageSize)) + 1;
    }
    setNumPages(num);
  }, [talents]);

  const previous = () => {
    let previousPage = current - 1;
    // console.log(previousPage)
    if (previousPage >= 1) {
      getPageNum(previousPage);
      setCurrent(previousPage);
    }
  };
  const forward = () => {
    let nextPage = current + 1;
    if (!(nextPage > numPages)) {
      getPageNum(nextPage);
      setCurrent(nextPage);
    }
  };
  return (
    <section class="pagintion-section">
      <div class="container">
        <div class="pagination-section__inner">
          <nav aria-label="Page navigation">
            <ul
              id="pagination-container"
              class="pagination justify-content-center custom-pagination"
            >
              <li
                class="page-item page-item--custom"
                onClick={() => previous()}
              >
                <a
                  class="page-link page-link--custom page-link-pre"
                  href="#"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">
                    <svg
                      class="page-icon-pre"
                      width="7"
                      height="14"
                      viewBox="0 0 7 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.75908 7.35936C1.6681 7.25864 1.57711 7.15793 1.48612 7.05721L6.09613 1.95424C6.33877 1.68567 6.5814 1.41709 6.82403 1.14851C7.36995 0.544214 6.52074 -0.429378 5.97482 0.208493C4.24606 2.08853 2.57797 3.935 0.909871 5.78147C0.667238 6.05005 0.424606 6.31862 0.181974 6.5872C-0.060658 6.85578 -0.060658 7.29222 0.181974 7.52722L5.15593 13.0331L5.8535 13.8052C6.39942 14.4095 7.27897 13.4695 6.70271 12.8652C5.06495 11.0523 3.39685 9.20583 1.75908 7.35936Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </li>
              {[...Array(numPages).keys()].map((num) => (
                <li
                  class={`page-item page-item--custom ${
                    current == num + 1 ? "active" : ""
                  }`}
                  onClick={() => {
                    getPageNum(num + 1);
                    setCurrent(num + 1);
                  }}
                >
                  <a class="page-link page-link--custom page-changer" href="#">
                    {` ${num + 1} `}
                  </a>
                </li>
              ))}

              <li class="page-item page-item--custom" onClick={() => forward()}>
                <a
                  class="page-link page-link--custom page-link-next"
                  href="#"
                  aria-label="Next"
                >
                  <span aria-hidden="true">
                    <svg
                      class="page-icon-next"
                      width="7"
                      height="14"
                      viewBox="0 0 7 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.24092 6.64064C5.3319 6.74136 5.42289 6.84207 5.51388 6.94279L0.903866 12.0458C0.661234 12.3143 0.418602 12.5829 0.17597 12.8515C-0.369952 13.4558 0.47926 14.4294 1.02518 13.7915C2.75394 11.9115 4.42203 10.065 6.09013 8.21853C6.33276 7.94995 6.57539 7.68138 6.81803 7.4128C7.06066 7.14422 7.06066 6.70778 6.81803 6.47278L1.84407 0.966946L1.1465 0.194785C0.600577 -0.409513 -0.278966 0.530508 0.297286 1.13481C1.93505 2.9477 3.60315 4.79417 5.24092 6.64064Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default Pagination;
