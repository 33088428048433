import React from "react";
import { PopupButton } from "@typeform/embed-react";

function EuroCompanies() {
  return (
    <section class="section section--top-euro-companies">
      <div class="section__content">
        <h2 class="section__title">
          We connect you directly with the top companies in Europe
        </h2>
        <p class="section__desc">
          Factory Digitale engagements are full-time and long-term. As one
          project nears completion, our team goes to work to identify the next
          one for you within weeks.
        </p>
        <PopupButton
          id="rjDfDCxF"
          size={75}
          //   data-tf-iframe-props="title=Join our team"
          //   data-tf-medium="snippet"
          //   href="job-details.html"
          className="btn btn-primary"
        >
          Apply for jobs
        </PopupButton>
      </div>
      <div class="section__img">
        <img
          src="assets/img/top-companies.png"
          srcset="assets/img/top-companies@2x.png 2x"
        />
      </div>
    </section>
  );
}

export default EuroCompanies;
