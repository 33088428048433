import React from "react";

function TestimonialDark() {
  return (
    <section class="section section--testimonial section--dark">
      <div class="testimonial-card testimonial-card--black">
        <div class="testimonial-card__content">
          <h4 class="section__subtitle">Don’t just take our word for it</h4>
          <p class="testimonial-card__desc">
            Factory digitale was a life-changing decision for me. What I like
            about Factory Digitale is that they delivered on what they promised,
            a steady stream of income and career growth !
          </p>
          <div class="testimonial-card__name">Abdelmalek, Algeria</div>
          <div class="testimonial-card__job">
            <span>Data Scientist at</span>
            <img src="assets/img/logo_peers.svg" alt="Logo peers & peers" />
          </div>
        </div>
        <div class="testimonial-card__img">
          <img
            class="img-fluid w-100"
            src="assets/img/testimon-malek.jpg"
            srcset="assets/img/testimon-malek.jpg 2x"
            alt=""
          />
          <div class="testimonial-card__squares">
            <div class="square square--black top-0 start-0"></div>
            <div class="square square--light-primary top-0 end-0 square--doublew">
              <div class="position-absolute square square--primary top-100 end-100"></div>
            </div>
            <div class="square square--white bottom-0 end-0"></div>
            <div class="square square--doublew square--primary bottom-0 start-0"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialDark;
