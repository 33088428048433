import React from 'react'

function SchoolInfo() {
  return (
    <section class="school-info">
    <div class="container">
        <div class="fd-info__inner">
            <h1 class="big-title">Check our coding school</h1>
            <div class="school-info__desc">
                <div class="row justify-content-end">
                    <div class="col-12 col-lg-8">
                        <p class="school-info__para">We train the next tech generation on the most innovative and recent technologies.</p>
                        <p class="school-info__para">We help them get ready for the jobs of the future and prepare them for their professional endeavour while reinforcing their employability</p>
                        <p class="school-info__para">Most famous courses we have: full stack development, AI, data, webmarketing, mobile development, business intelligence.</p>
                        <a href="https://code213.tech/" target="_blank" class="btn btn-primary" rel="noreferrer">Visit CODE 213</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  )
}

export default SchoolInfo